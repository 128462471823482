import React from 'react';

class NoPage extends React.Component {
  render(){
    return (
      <>
        <span id="uploadPage">
          <h2>404 - We couldn't find this page :(</h2>
        </span>
      </>
    );
  }
}

export default NoPage;