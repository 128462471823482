import React from 'react';

function Collections() {  
  return (
    <>
      <h2>Collections coming soon...</h2>
    </>
  );
}

export default Collections;